<template>
    <div>
        <div v-if="mutableList && mutableList.length" class="slick_box list_box">
            <VueSlickCarousel v-bind="settings">
                <template #prevArrow="arrowOption">
                    <div class="custom-arrow">
                        {{ arrowOption.currentSlide }}/{{ arrowOption.slideCount }}
                    </div>
                </template>
                <div v-for="(item,num) in mutableList" :key="num">
                    <img :src="item.image" alt="" class="img-fluid" @click="index = num" itemprop="image" >
                </div>
                <template #nextArrow="arrowOption">
                    <div class="custom-arrow">
                        {{ arrowOption.currentSlide }}/{{ arrowOption.slideCount }}
                    </div>
                </template>
            </VueSlickCarousel>
            <div>
                <Tinybox v-model="index" :images="mutableList" loop class="tinybox_main" />
            </div>
        </div>
        <div v-else-if="items && items.length" class="slick_box list_box">
            <VueSlickCarousel v-bind="settings">
                <template #prevArrow="arrowOption">
                    <div class="custom-arrow">
                        {{ arrowOption.currentSlide }}/{{ arrowOption.slideCount }}
                    </div>
                </template>
                <div v-for="(item,num) in items" :key="num">
                    <img :src="item" alt="" class="img-fluid" @click="index = num" itemprop="image" >
                </div>
                <template #nextArrow="arrowOption">
                    <div class="custom-arrow">
                        {{ arrowOption.currentSlide }}/{{ arrowOption.slideCount }}
                    </div>
                </template>
            </VueSlickCarousel>
            <div>
                <Tinybox v-model="index" :images="items" loop class="tinybox_main" />
            </div>
        </div>
        <div v-else-if="src" class="slick_box slick_src_item" :class="align ? 'marginAuto' : ''">

                    <img :src="src" alt="" class="img-fluid" @click="index = 0"  itemprop="image">

            <div>
                <Tinybox v-model="index" :images="[src]" no-thumbs class="tinybox_main" />
            </div>
        </div>
    </div>
</template>
<script>
import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';
export default {
    name: 'imageLoader',
    components: {
        VueSlickCarousel,
    },
    props: {
        src: String,
        list: Array,
        items: Array,
        align: Boolean
    },
    data() {
        return {
            mutableList: this.list,
            index: null,
            images_array: [],
            settings: {
                arrows: true,
                dots: true,
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                autoplay: true,
                swipe: false,
                speed: 500,
                autoplaySpeed: 5000,
                adaptiveHeight: false,
                pauseOnDotsHover: true,
                pauseOnFocus: true,
                pauseOnHover: true,
                responsive: [{
                    breakpoint: 768,
                    settings: {
                        swipe: true,
                        slidesToShow: 1,
                        arrows: false,
                        adaptiveHeight: true,
                        autoplay: false,
                    }
                }]
            },
        }
    },
    methods: {

    },
    mounted() {
        if (this.mutableList && this.mutableList.length) {
            this.mutableList.map((e, key) => {
                return this.mutableList[key].src = e.image;
            })

        }

    },

}
</script>
<style scoped>
.slick_src_item {
    display: flex;
}

.slick-slider {
    display: grid;
}
.slick_src_item img {
    max-height: 500px;
    border-radius: 12px;
    cursor: pointer;
}
.slick_box {
    padding: 15px;
}

.marginAuto img {
    margin: auto auto;

}

.list_box img {
    margin: auto auto;
}

.slick-slider img {
    max-height: 500px;
    border-radius: 12px;
    cursor: pointer;
}

.custom-arrow:before {
    color: rgb(74, 118, 168);
}

.tinybox_main {
    /* overflow: hidden !important; */
}
</style>